import PropTypes from 'prop-types';
import React from 'react';

import HealthStandardsBadge from '@/components/covid-stuff/HealthStandardsBadge';
import Image from '@/components/graphics/Image';
import Badge from '@/components/labels/Badge';
import BadgeList from '@/components/labels/BadgeList';
import SquareBadge from '@/components/rooms-or-spaces/SquareBadge';

import UserContext from '@/context/User';

import * as S from './RoomOrSpaceImage.styles';

const RoomOrSpaceImage = React.forwardRef(
  (
    {
      featured,
      highlighted,
      hq,
      isSafeSpace,
      bookInstantly,
      showLogo,
      renderOverImage,
      size,
      rightBadgePadding,
      ...imageProps
    },
    ref,
  ) => {
    const { teamUser } = React.useContext(UserContext);

    const shouldRenderBottomRight =
      (isSafeSpace || bookInstantly) && !renderOverImage.bottomRight;

    const shouldRenderTopLeft =
      (teamUser || showLogo) && !renderOverImage.topLeft;

    return (
      <S.RoomOrSpaceImage ref={ref}>
        <Image {...imageProps} />

        {shouldRenderBottomRight && (
          <S.RoomOrSpaceImagePlacement
            placement="bottomRight"
            rightBadgePadding={rightBadgePadding}
          >
            <BadgeList>
              {isSafeSpace && <HealthStandardsBadge {...(size && { size })} />}

              {bookInstantly && (
                <Badge
                  color="white"
                  icon="bolt-17"
                  iconProps={{ color: '#00b0b1' }}
                  {...(size && { size })}
                >
                  <strong>Book Instantly</strong>
                </Badge>
              )}
            </BadgeList>
          </S.RoomOrSpaceImagePlacement>
        )}

        {shouldRenderTopLeft && (
          <S.RoomOrSpaceImagePlacement placement="topLeft">
            <SquareBadge
              hq={hq}
              showLogo={showLogo}
              highlighted={highlighted}
              featured={featured}
              {...(size && { size })}
            />
          </S.RoomOrSpaceImagePlacement>
        )}

        {Object.keys(renderOverImage).map((placement) => {
          const renderOnPlacement = renderOverImage[placement];
          if (typeof renderOnPlacement === 'function') {
            return (
              <S.RoomOrSpaceImagePlacement
                key={placement}
                placement={placement}
              >
                {renderOnPlacement({ placement, sizeModifier: size })}
              </S.RoomOrSpaceImagePlacement>
            );
          }

          return null;
        })}
      </S.RoomOrSpaceImage>
    );
  },
);

RoomOrSpaceImage.defaultProps = {
  renderOverImage: {},
  highlighted: false,
  featured: false,
  showLogo: false,
  isSafeSpace: false,
  bookInstantly: false,
  hq: false,
  rightBadgePadding: false,
};

RoomOrSpaceImage.propTypes = {
  renderOverImage: PropTypes.shape({
    topRight: PropTypes.func,
    topLeft: PropTypes.func,
    bottomLeft: PropTypes.func,
    bottomRight: PropTypes.func,
  }),
  bookInstantly: PropTypes.bool,
  featured: PropTypes.bool,
  highlighted: PropTypes.bool,
  showLogo: PropTypes.bool,
  isSafeSpace: PropTypes.bool,
  hq: PropTypes.bool,
  rightBadgePadding: PropTypes.bool,
  ...Image.propTypes,
};

export default RoomOrSpaceImage;
