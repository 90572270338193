import styled, { css } from 'styled-components';

import SvgUse from '@/components/graphics/SvgUse';
import Badge from '@/components/labels/Badge';

/**
 * Get StyledHealthStandardsBadge size styles
 * @param {String} size - `medium | large`
 * @returns
 */
const getSizeStyles = ({ size }) =>
  ({
    medium: css`
      svg {
        width: 12px;
        height: 12px;
      }
    `,
    large: css`
      svg {
        width: 13px;
        height: 13px;
      }
    `,
  }[size]);

const StyledHealthStandardsBadge = styled(Badge)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.colors.primary};
    margin: 1px 4px 0;
    width: 11px;
    height: 11px;
  }

  ${getSizeStyles}
`;

/**
 * HealthStandardsBadge
 * ---
 * @param {String} size - `small | medium | large`
 *
 * Other component props
 * - Badge
 */
const HealthStandardsBadge = ({ ...badgeProps }) => {
  return (
    <StyledHealthStandardsBadge color="white" {...badgeProps}>
      <SvgUse id="safety-pledge-icon" category="icons" />
      <strong>Safety Pledge</strong>
    </StyledHealthStandardsBadge>
  );
};

export default HealthStandardsBadge;
